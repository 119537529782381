<template>
  <div class="box">
    <el-breadcrumb class="m_t_15 m_b_20" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/onlineTeaching/index' }">在线教学</el-breadcrumb-item>

      <el-breadcrumb-item>{{ type == 1 ? '教学指导' : '电子教材' }}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  资源板块  -->
    <div class="title">
      <img :src="type==1?kecheng:jiaocai" width="45px">
      <div class="m_lr_10 fw_bold">{{ type == 1 ? '教学指导' : '电子教材' }}</div>
    </div>
    <!--  目录筛选  -->
    <div class="flex_row">
      <div :class="type==1?'xueduan jxzd':'xueduan dzjc'">
        <div v-for="(items,index1) in selectData" :key="index1" v-show="index1==0">
          <div class="classifyName" style="text-align: left;">{{ classifyName[type - 1][index1] }}</div>
          <el-radio-group v-model="selected[index1]" :text-color="type==1?'#009CE0':'#0BB085'" fill="rgba(0,0,0,0)">
            <el-radio-button @change.native="catalogue_click(item,index1)" v-for="(item,index2) in items" :key="index2"
                             class="m-b-5" :label="item.id">{{ item.label }}
            </el-radio-button>
          </el-radio-group>
          <div :class="index1>0?'test':''"></div>
        </div>
      </div>
      <div style="width: 100%;margin-bottom: 40px;">
        <div :class="type==1?'kczy':'jczy'" v-show="selectData.length>1">
          <div v-for="(items,index1) in selectData" :key="index1" style="display: flex;flex-direction: row;"
               v-show="index1>0">
            <!-- 学前不显示左侧分类名-->
            <div class="classifyName" v-if="selected[0]!='1565668637077979138'">{{ classifyName[type - 1][index1] }}
            </div>
            <div v-else style="padding-left: 20px;"></div>
            <el-radio-group v-model="selected[index1]" :text-color="type==1?'#009CE0':'#0BB085'" fill="rgba(0,0,0,0)">
              <el-radio-button @change.native="catalogue_click(item,index1)" v-for="(item,index2) in items"
                               :key="index2" class="m-b-5" :label="item.id">{{ item.label }}
              </el-radio-button>
            </el-radio-group>
            <div :class="index1>1?'test':''"></div>
          </div>
        </div>
        <!--  资源列表  -->
        <div
            :style="type==1?'background-color: #fff;padding: 10px;border-radius: 5px;min-height:86vh':'padding: 10px;border-radius: 5px;min-height:56vh'">
          <div v-loading="loading" style="width: 100%;">
            <div v-if="resourceList.length!==0">
              <div class="resource-list flex_row_wrap" v-if="type==1">
                <Resource class="m_b_20 resource-item" v-for="resource of resourceList" :key="resource.id"
                          :resourceData="resource"/>
              </div>
              <div v-else>
                <div v-for="resource of resourceList" :key="resource.id"
                     style="cursor: pointer;background-color: #fff;margin: 20px 0; font-size:1.6rem;padding:15px 10px;border-radius: 5px;display: flex;justify-content:space-between;align-items: center;"
                     @click="toDetail(resource.id)">
                  <img :src="icon" width="30px"/>
                  <div style="text-align: left;width: 92%;">{{ resource.name }}</div>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <el-empty v-else description="暂无内容"></el-empty>
            <!--  分页  -->
            <div class="flex_row" style="justify-content:flex-end">
              <el-pagination class="m_b_20" background layout="prev, pager, next" @current-change="current_change"
                             :current-page.sync="paginationParams.currentPage" :page-size="paginationParams.size"
                             :total="paginationParams.total" hide-on-single-page>
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jiaocai from 'assets/image/online/chapter 1@2x.png'
import kecheng from 'assets/image/online/user-guide 1@2x.png'
import icon from 'assets/image/online/Group 498@2x.png'
import Resource from './components/resource/Resource'
// import FilterDirectory from './components/resource/FilterDirectory'
import {
  getResourceClassify,
  getResourceList,
  getResourceClassifyTree,
} from "@/api/onlineTeaching/detail";

export default {
  name: 'MunicipalLevel',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Resource,
    // FilterDirectory
  },
  data: () => {
    return {
      jiaocai,
      kecheng,
      icon,
      classifyName: [
        [
          '学段', '年级', '学科'
        ],
        [
          '学段', '年级', '学科', '版本', '册次'
        ]
      ],
      // 目录列表
      classifyList: [],
      // 资源列表
      resourceList: [],
      // 请求参数
      resourceParams: {
        // 资源分类id
        classifyId: '',
        // 资源目录ids
        contentIds: '',
        // 资源目录id
        contentsId: '',
        // 当前页
        current: 1,
        // 资源ids
        ids: '',
        // 资源标签
        label: '',
        // 资源名称
        name: '',
        // 每页数量
        size: 12,
        // 资源排序 1浏览最多(热门推荐) 2点赞最多 3收藏最多(精品推荐)
        sortDiv: '',
        // 资源类型
        type: '',
        // 1国家资源2省资源3市资源4区资源5空中课堂
        level: 4
      },
      // 选中的目录
      selectedClassify: null,
      // 子目录
      childClassifyList: {},
      // 选中的子目录
      selectedChildClassify: '',
      // 分页
      paginationParams: {
        currentPage: 1,
        size: 12,
        total: 0
      },
      contentsId: '',
      loading: false,
      jiaocaiList1: [],
      jiaocaiList2: [],
      classifyItem: '', //资源分类信息
      type: '', //资源类型1课程资源2教材专区
      // 原始数据
      rawData: [],
      selectData: [], //重组后数据
      selected: [], //选中的数据
      selectIndex: '', //选中的第几项
      currSelectXueke: 0, //当前选中的学科
      index: 0,
    }
  },
  async mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let params = this.$route.query;
    this.type = params.type;


    //获取目录
    await getResourceClassify({
      level: 4
    }).then(res => {
      this.classifyItem = res.data.find(item => this.type == 1 ? item.name == '教学指导' : item.name == '电子教材');
    })
    //获取筛选项
    var {
      data
    } = await getResourceClassifyTree({
      id: this.classifyItem.id
    })
    this.rawData = data[0];

    if (params.ids) {
      this.loading = true;
      //遍历全部筛选项
      //this.setDefault(data[0]);
      //this.selectData.push(data[0].children);

      //选中参数
      this.selected = params.ids;

      //查询数据
      let ttt = [];
      this.selected.map(val => {
        if (val != '') {
          ttt.push(val);
        }
      })
      this.resourceParams.contentsId = ttt[ttt.length - 1];
      this.getResourceList();
      //设置默认选项
      this.setDefault(data[0]);
    } else {
      //外部跳转进来展示全部数据
      this.catalogue_click(data[0]);
    }

  },
  methods: {
    //设置默认数据
    setDefault(items) {
      if (items) {
        this.selectDefault(items.children);
      }
    },
    selectDefault(item) {
      if (item.length > 0)
        this.selectData.push(item);
      this.index++;
      if (this.index < this.selected.length + 1) {
        //当id存在选中指定项
        if (this.selected[this.index - 1]) {
          let id = this.selected[this.index - 1];
          let res = item.find(item => item.id == id);
          this.setDefault(res);
        } else {
          //当id为空选中第一个
          this.setDefault(item[0]);
        }

      }


    },
    //递归获取数据
    getDatas(items) {
      this.selectData.push(items);
      //默认点击第一项
      this.catalogue_click(items[0]);
      //默认选中后续第一项
      //this.selected[items[0].level - 1] = items[0].id;
    },
    //点击目录
    async catalogue_click(item, index) {
      if (index >= 0) {
        this.selectIndex = index;
      }
      //清空点击索引栏下的数据
      this.selectData.splice(item.level, this.selectData.length);
      //获取点击目录
      if (item.children.length > 0) {
        this.getDatas(item.children);
      } else {
        //点击查询操作
        if (this.selected.length > 0) {
          //清空之前的选择;
          this.selected.splice(this.selectIndex + 1);
          this.resourceParams.current = 1;
          this.paginationParams.currentPage = 1;
          this.resourceParams.contentsId = this.selected[this.selected.length - 1];
          //获取数据
          this.getResourceList();
        }
      }
    },
    // 获取资源列表
    getResourceList() {
      if (this.type == 2) {
        this.resourceParams.size = 8
      } else {
        if (this.resourceParams.classifyId == '1541601152817176578') {
          this.resourceParams.size = 10
        } else {
          this.resourceParams.size = 12
        }
      }

      this.loading = true
      getResourceList(this.resourceParams).then(res => {
        if (this.resourceParams.classifyId == '1541601152817176578') {
          this.jiaocaiList1 = res.data.records.slice(0, 6)
          this.jiaocaiList2 = res.data.records.slice(6, 10)
        } else {
          this.resourceList = res.data.records
        }
        this.paginationParams.total = res.data.total
        this.loading = false
      })


    },
    toDetail(id) {
      this.$router.push({
        path: '/resource/details',
        query: {
          id: id,
          scene: 'dzjc'
        }
      });
    },
    // 切换分页
    current_change(nowCurrent) {
      this.resourceParams.current = nowCurrent
      this.getResourceList()
    },
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 1200px;
  margin: auto;

  .xueduan {
    width: 230px;
    margin-right: 15px;
    background-color: #fff;
    margin-bottom: 40px;
    height: 100vh;
    padding: 10px;
    border-radius: 5px;
    flex: none;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 15px 0;
  }

  ::deep .el-radio-group {
    width: 100%;
  }

  ::deep .xueduan .el-radio-button {
    display: block;
    padding-left: 25px;
  }

  ::deep .jxzd .is-active {
    background-color: #ecf9ff;
  }

  ::deep .dzjc .is-active {
    background-color: #effffb;
  }

  ::deep .el-radio-button__inner {
    background: none;
    border: none;
    padding: 13px 25px 12px 0;
    font-size: 1.6rem;
  }

  ::deep .el-radio-button__inner:hover {
    color: #000;
  }

  ::deep .el-radio-button:first-child .el-radio-button__inner {
    border: none;
  }

  .kczy {
    border-radius: 5px;
    padding: 15px 0;
    margin-bottom: 20px;
    background: url("../../assets/image/online/Group 856@2x.png");
    background-size: 100% 100%;

    .test {
      width: 750px;
      border: 1px #c9ebfb dashed;
      position: absolute;
      margin-left: 70px;
      /* 自动内减 */
      box-sizing: border-box;
    }
  }

  .jczy {
    border-radius: 5px;
    background: url("../../assets/image/online/Group 855@2x.png");
    background-size: 100% 100%;
    width: 100%;
    padding: 15px 0;

    .test {
      width: 750px;
      border: 1px #c6efe3 dashed;
      position: absolute;
      margin-left: 70px;
      /* 自动内减 */
      box-sizing: border-box;
    }
  }

  .classifyName {
    width: 70px;
    font-weight: bold;
    line-height: 41px;
    text-align: center;
    font-size: 1.6rem;
  }

  .item.active {
    background-color: #e8f9f5;
  }

  .item {
    text-align: center;
    width: 60px;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 14px;
  }
}

.plate-box {
  justify-content: space-between;
  position: relative;
}

.plate {
  width: 136px;
  height: 144px;
  text-align: center;
}

.recourse-box {
  width: 23.7%;
}

.resource-item {
  margin-right: 16px;
}

.resource-item:nth-child(4n) {
  margin-right: 0;
}

.select-classify {
  background-color: #e3f4f0;
}


.region-resource-item {
  margin-right: 20px;
  width: 285px;
  height: 211px;
}
</style>
